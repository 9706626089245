<template>
  <b-card-code>
    <b-form @submit.prevent="save(item)">
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Tipo"
            label-for="type"
          >
            <b-form-input
              id="type"
              v-model="item.tipo"
              placeholder="Tipo"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Prefijo"
            label-for="prefijo"
          >
            <b-form-input
              id="prefijo"
              v-model="item.prefijo"
              placeholder="prefijo"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Estado"
            label-for="status"
          >
            <v-select
              id="status"
              v-model="item.status"
              label="status"
              :options="statuses"
              :reduce="option => option.idStatus"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="loading"
          >
            <span v-if="!loading">Guardar</span>
            <span v-if="loading">Un momento...</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            to="../accounts-types"
          >
            Cancelar
          </b-button>
        </b-col>

        <p>{{ errorMessage }}</p>
      </b-row>
    </b-form>
  </b-card-code>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@core/components/b-card-code'
import environment from '@/environment'

export default {
  name: 'CentersTypesAddVue',
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      item: {},
      loading: true,
      errorMessage: '',
    }
  },
  computed: {
    statuses() {
      return this.$store.getters['catalog/allStatuses']
    },
  },
  created() {
    this.load()
  },
  methods: {
    async load() {
      try {
        this.loading = true
        const { data } = await this.$http.get(`${environment.uri}/actypes/id/${this.$route.params.id}`)
        this.item = data
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    save(item) {
      this.loading = true
      this.errorMessage = ''
      this.$http.put(`${environment.uri}/actypes/update/id/${this.$route.params.id}`, item)
        .then(() => {
          this.loading = false
          this.$router.replace('/cda/accounts-types')
        })
        .catch(error => {
          console.error(error)
          this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
          this.loading = false
        })
    },
  },
}
</script>

<style scoped>

</style>
